
function LoadingScreen() {
    return (
        <>
            <div className={'loading-screen'} >
                <div className='loading-screen__circle'></div>
            </div>
        </>
    )
}

export default LoadingScreen;